import React, { useEffect, useRef, useState } from "react";
import "./Header.scss";
import history from "../../../history";
import logo from "../../../img/logo.png";
import logoSchool from "../../../img/Logo_NodoSchool.svg";
import { Link } from "react-router-dom";

function Header(props) {
  const toggleMenuRef = useRef(null);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (e) => {
    const scrollValue = e.target.scrollingElement.scrollTop;
    setScroll(scrollValue);
  };

  const handleMenuClick = (e, origin) => {
    e.preventDefault();
    const URL = window.location.href.split("/").pop();
    toggleMenuRef.current.click();
    switch (origin) {
      case "services":
        if (URL !== "#") {
          history.push("/");
          setTimeout(() => {
            scrollInto("sectionServices");
          }, 500);
        } else {
          scrollInto("sectionServices");
        }
        break;
      case "contact":
        if (URL !== "#") {
          history.push("/");
          setTimeout(() => {
            scrollInto("sectionContact");
          }, 500);
        } else {
          scrollInto("sectionContact");
        }
        break;
      default:
        break;
    }
    history.push("/");
  };

  const scrollInto = (view) => {
    document.getElementById(view).scrollIntoView();
  };

  return (
    <header className={`Header ${scroll > 0 ? "Header__bg" : ""}`}>
      <div className="Header__logo">
        <Link to="/">
          <img className="Header__img" src={logo} alt="Logo NodoLab" />
        </Link>
      </div>
      <nav className="Header__navbar">
        <ul className="navbar">
          <li className="navbar__item">
            <a
              className="navbar__link"
              target="_blank"
              href="https://drive.google.com/file/d/14CkXy0PGKnOJF0tjEy3gNcStIt0vsbJw/view"
            >
              Servicios
            </a>
          </li>
          <li className="navbar__item">
            <a
              className="navbar__link"
              target="_blank"
              href="https://www.linkedin.com/company/nodolabcollective/"
            >
              Sobre Nosotros
            </a>
          </li>
          <li className="navbar__item">
            <a
              className="navbar__link"
              href="#"
              onClick={(e) => {
                handleMenuClick(e, "contact");
              }}
            >
              Contacto
            </a>
          </li>
          <li className="navbar__item">
            <a
              target="_blank"
              className="navbar__link navbar__link--image"
              href="https://nodolab.co/lms"
            >
              <img src={logoSchool} alt="Logo nodo school" />
            </a>
          </li>
        </ul>
      </nav>
      <div className="navigation">
        <input
          type="checkbox"
          id="navi-toggle"
          className="navigation__checkbox"
        />
        <label
          htmlFor="navi-toggle"
          className="navigation__button"
          ref={toggleMenuRef}
        >
          <span className="navigation__icon">&nbsp;</span>
        </label>
        <div className="navigation__background">&nbsp;</div>

        <nav className="navigation__nav">
          <ul className="navigation__list">
            <li className="navigation__item">
              <a
                className="navigation__link"
                target="_blank"
                href="https://drive.google.com/file/d/14CkXy0PGKnOJF0tjEy3gNcStIt0vsbJw/view"
              >
                Servicios
              </a>
            </li>
            <li className="navigation__item">
              <a
                className="navigation__link"
                target="_blank"
                href="https://www.linkedin.com/company/nodolabcollective/"
              >
                Sobre Nosotros
              </a>
            </li>
            <li className="navigation__item">
              <a
                className="navigation__link"
                href="#"
                onClick={(e) => {
                  handleMenuClick(e, "contact");
                }}
              >
                Contacto
              </a>
            </li>
            <li className="navigation__item">
              <a
                target="_blank"
                className="navigation__link navigation__link--image"
                href="https://nodolab.co/lms"
              >
                <img src={logoSchool} alt="Logo nodo school" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;

import Header from "../src/components/organisms/Header/Header";

import "./App.scss";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import Main from "./pages/Main/Main";
import history from "./history";

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Header className="Header"></Header>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

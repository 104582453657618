import React from 'react';
import './ExperiencesAdvice.scss';
import floatingIcon from '../../../img/s3_icono4.png';


const ExperiencesAdvice = (props) => {
  return (
    <div className="ExperiencesAdvice">
      <img className="ExperiencesAdvice__icon" src={floatingIcon} alt=""/>
      <div className="ExperiencesAdvice__heading heading-sec">
        Materializamos experiencias <span className="heading-sec--italic ExperiencesAdvice__heading--italic">digitales interactivas</span>
      </div>
    </div>
  );
};

export default ExperiencesAdvice;
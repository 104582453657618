import React from 'react';
import './ServicesAdvice.scss';

import videoWebm from '../../../img/meet.webm';
import videoMp4 from '../../../img/meet.mp4';
import floatingIcon from '../../../img/s2_floating_icon.png';


const ServicesAdvice = (props) => {
  return (
    <div className="servicesAdvice">
      <div className="servicesAdvice__video">
        <video className="servicesAdvice__video__content" autoPlay muted loop>
          <source src={videoMp4} type="video/mp4" />
          <source src={videoWebm} type="video/webm" />
          Your browser is not supported!
        </video>
      </div>
      <div className="servicesAdvice__content">
        <img className="servicesAdvice__icon" src={floatingIcon} alt=""/>
        <div className="servicesAdvice__container servicesAdvice__container--1">
          <h2 className="heading-sec servicesAdvice__caps">
            Transformación digital para democratizar <span className="heading-sec--italic">la educación</span>
          </h2>
        </div>
        <div className="servicesAdvice__container servicesAdvice__container--2">
          <p className="paragraph">Nuestros servicios están soportados en la <span className="paragraph--green">experiencia</span> y el <span className="paragraph--green">conocimiento</span> para crear recursos digitales de alta calidad e implementar entornos de aprendizaje innovadores.</p>
        </div>
      </div>
    </div>
  );
}

export default ServicesAdvice;